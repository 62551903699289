@import '../../../Styles/shared.scss';

.container{
  height:100vh;
  width:100vw;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background-color: #6a6a6a33;
  z-index: 10;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);

  .inner{
    background-color: white;
    border-radius: 15px;
    padding: 50px 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    transform-style: preserve-3d;

    &:before{
      content: "";
      height: 100%;
      width: 100%;
      background-color: #D0D6D6;
      border-radius: 15px;
      position: absolute;
      top: 10px;
      left: -10px;
      transform: translateZ(-1px);
    }

    .close{
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      background: url('../../../Assets/Icons/closeIcon2.svg') no-repeat center/contain;
      height: 15px;
      width: 15px;
    }

    h5{
      margin:0 0 20px 0;
      font-size: 25px;
      font-weight: 700;
    }

    .options{
      display: flex;

      .option{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height:190px;
        width:200px;
        margin: 0 5px;
        border:1px solid transparent;
        border-radius: 10px;
        transition: .3s all ease;
        cursor: pointer;
        box-sizing:border-box;
        padding:25px 0 10px 0;

        &:hover{
          border-color: $faith-pink;
        }

        p{
          font-size: 18px;
          font-weight: 700;
        }

        .icon{
          height: 90px;
          width: 90px;
        }

        &.desktop .icon{
          background: url('../../../Assets/illustrations/desktop.svg') no-repeat center/contain;
        }

        &.mobile .icon{
          background: url('../../../Assets/illustrations/mobile.svg') no-repeat center/contain;
        }
      }
    }

    @media (max-width:660px){
      padding: 50px;
      h5{
        width: 60vw;
        min-width: 350px;
      }

      .options{
        flex-direction: column;
        .option{
          margin: 5px 0;;
        }
      }
    }
  }
}