@import "../../Styles/shared.scss";
.loginSection {
  display: grid;
  grid-template-columns: 1fr minmax(300px, 36.5%);
  width: 100vw;
  height: 100vh;
  overflow: auto;
  .leftSide {
    display: flex;
    flex-direction: column;
  }
  .rightSide {
    position: relative;


    h2 {
      color: $main-brown !important;
    }


    .whiteBlock{
      box-sizing:border-box;
      padding:40px 10px;
      width: 480px;
      background-color: #ffffffa4;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      margin: 0 auto;

      img{
        width: 300px;
        margin: 10px 0 20px 0;
      }
    }

    h1 {
      font-size: 50px;
      font-weight: 900;
      line-height: 55px;
    }
    &.student,
    &.teacher {
      background: url('../../Assets/homepage/bg-hero.png') no-repeat bottom right 10%;
      background-size: cover;
      box-sizing:border-box;
      padding:30px 0;
      h2 {
        color: $main-brown !important;
      }
    }
  }
}

.form {
  text-align: left;
  .registerForm {

    &.confirmEmail{
      width: 45%;
      margin:10% auto;
    }
    .inputDiv {
      display: flex;
      flex-direction: column;
      max-width: 380px;


      &.error {
        animation: error 0.5s ease;
        .input {
          border-color: $main-pink;
        }
      }

      &.half {
        width: 47%;
      }
      label {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        text-align: left;
        margin-bottom: 0.5em;
        display: flex;
        justify-content: space-between;
        a {
          color: $main-grey;
          font-weight: normal;
        }
      }

      .errorMessage {
        color: $main-pink;
        text-align: left;
        font-size: 12px;
        margin: 0;
        height: 25px;
        animation: error 0.5s ease;
      }

      .input {
        border: 1px solid #cacaca;
        border-radius: 5px;
        padding: 0.4em 0.7em;
        font-size: 16px;

      }

      .iconInputDiv {
        display: flex;
        border: 1px solid #cacaca;
        border-radius: 5px;
        justify-content: space-between;
        padding-right: 0.65em;

        &.error {
          border-color: $main-pink;
          animation: error 0.5s ease;
        }
        img {
          width: 25px;
        }
        .input {
          border: none;
          padding: none;
          flex: 2;
        }
      }
    }
      
    .submitPassBtn {
      width: 185px;
      height: 38px;
      @include btn(white, $main-green, 4em);
      margin: 0.5em 0;
      padding: 0.5em 2em;
      border-radius: 8px;

      &.errors {
        animation: error 0.5s ease;

        @include btn(white, $main-pink, 4em);
        // padding: 0.5em 4em;
        padding: 0;
        &:hover{
          .pulseBubble{
            background-color: $main-pink;
          }
        }
      }

      &:hover{
        .pulseBubble{
          background-color: $main-green;
        }
      }
    }
  }
  .greenBtn {
    height: 40px;
    width: 160px;
    input {
      @include btn(white, $main-green, 4em);
      margin: 0.5em 0;
      border-radius: 8px;
      border:1px solid purple;
      height: 40px;
      padding: 0 !important;
      width: 250px;
    }
    &.errors {
      animation: error 0.5s ease;
      input {
        @include btn(white, $main-pink, 4em);
        padding: 0.5em 4em;
      }
    }
  }
  .infoTerms {
    margin: 0.5em;
    .checkboxText {
      display: flex;
      align-items: center;
      .checkbox {
        width: 13px;
        height: 13px;
        background-image: url("../../Assets/Icons/whiteCheck.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-color: white;
        border: 2px solid $main-blue--bright;
        border-radius: 5px;
        margin-right: 0.5em;
        transition: all 0.2s ease;
        &.checked {
          background-color: $main-blue--bright;
        }
        &.error {
          border: 2px solid $main-pink;
        }
      }
      p {
        font-size: 14px;
        color: $main-grey;
        margin: 0;
        line-height: 16px;
      }
    }
    .little {
      color: $main-grey;
      font-size: 12px;
      margin: 0.5em;
    }
  }
  .secondEmailText {
    width: 600px;
    color: $main-grey;
    margin-top: 0;
    font-size: 14px;
    line-height: 16px;
  }
}

.authHeader{

  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;

  .back{
    margin: 1em 3em;
    color: $main-grey;
    cursor: pointer;
    font-size: 18px;
    .goBack{
      display: flex;
      align-items: center;
      img{
        height: 12px;
        margin-right: 10px;
      }
    }
  }

  .notAMember {
    margin: 1em 3em;
    text-align: right;
    p {
      color: $main-grey;
      a,
      .a {
        cursor: pointer;
        margin-left: 1em;
        color: $main-blue--bright;
      }
    }
  }
}

@media (max-width: 600px) {
  .authHeader {
    .back {
      margin: 10px;
    }
    .notAMember{
      margin: 10px;
      margin-left: 100px;
    }
  }
}

@media (max-width: 1200px) {
  .loginSection {
    .rightSide {
      h2 {
        margin-right: 0.5em;

        font-size: 36px;
      }
      &.teacher {
        background-position: -10vw 38vh;
      }
    }
  }
}


@media (max-width: 1200px) {
  
  .form {
    .secondEmailText {
      width: auto;
    }
  }
}

@media (max-width: 900px) {
  .loginSection {
    display: flex;
    justify-content: center;

    .rightSide {
      display: none;
    }
  }
  .form {
    .registerForm{

      .inputDiv {
        max-width: 460px;
      }
    }
  }
}



@media (max-width: 600px) {
  .loginSection {
    .leftSide {
      margin: 10px;
    }
  }
}

@keyframes error {
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0px);
  }
}
