@import '../../../../Styles/shared.scss';
@import '../../Homepage.module.scss';

.container{
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  .inner{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing:border-box;
    padding:0 50px;

    h2{
      width: 100%;
      text-align: center;
    }
  }

  .navigation {
    margin-left: auto;
    margin-right: 5%;
    display: flex;
    justify-content: flex-end;
    gap: 5px;

    .prev, .next {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      color: white;
      cursor: pointer;
      opacity: 0.9;

      &:hover {
        opacity: 1;
      }
    }

    .prev {
      background: url('../../../../Assets/Icons/arrow-small.svg') no-repeat center / 30px 30px, $faith-blue;
      transform: rotate(-180deg);
    }

    .next {
      background: url('../../../../Assets/Icons/arrow-small.svg') no-repeat center / 30px 30px, $faith-blue;
    }

    @media (max-width: 750px){
      margin-right: 15%;
    }

    @media (max-width: 650px){
      display: none;
    }
  }

  .cards{
    width: 99%;
    display: flex;
    overflow: hidden;
    justify-content: center;
    opacity: 0.4;

    &.show{
      opacity: 1;
      transition: .5s all ease-in;
    }

    @media (max-width: 650px){
      width: 90%;
      flex-flow: column;
      align-items: center;

      >div.card {
        display: block;
      }
    }
  }

  @media (max-width:950px){
    .inner{
      justify-content: center;
    }
    h2{
      text-align: center !important;
      max-width: 80vw;
    }
  }
}

.card{
  display: flex;
  width: 30%;
  height: fit-content;
  border-radius: 20px;
  flex-direction: column;
  box-sizing:border-box;
  padding:20px;
  cursor: pointer;

  &:nth-of-type(5){
    display: none;
  }

  .imgWrapper{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
      border-radius: 20px;
      overflow: hidden;
      width: 100%;
    }

    .play{
      height:50%;
      width:50%;
      background: url('../../../../Assets/Icons/playBtn.png') no-repeat center/contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: .3s all ease;
      opacity: 0;
      z-index: 1000;
    }
  }

  .details{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6{
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 700;
      margin:10px 0;

      &.type{
        color: $faith-neptune;
      }
      &.duration{
        color: $faith-pink;
      }
    }
  }

  h5{
    font-size: 18px;
    line-height: 23px;
    margin: 10px 0;
  }
  p{
    font-size: 16px;
    line-height: 20px;
    margin-top: 5px;
  }

  &:hover{
    .imgWrapper{
      .play{
        opacity: 1;
      }
    }
  }

  @media (max-width: 1000px){
    width: 31%;
    &:nth-of-type(4){
      display: none;
    }
  }

  @media (max-width: 750px){
    width: 40%;
    &:nth-of-type(3){
      display: none;
    }
  }
}