@import './Fonts.scss';


@mixin btn($color, $bg, $padding) {
  cursor: pointer;
  background: $bg;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: $color;
  text-align: center;
  padding: 0.75em $padding;
  margin: 0.5em 0em;
  display: inline-block;
  border: 1.5px solid $bg;
  transition: all 0.5s ease;

  &:hover {
    background-color: $color;
    color: $bg;
    border: 1.5px solid $bg;
  }
}

@mixin solidButton ($color, $bg, $font-size, $height, $width) {
  height:$height;
  width:$width;
  color:$color;
  font-size: $font-size;
  background-color: $bg;
  border:1px solid $bg;
  text-align: center;
  line-height: $height;
  cursor: pointer;
  transition: .3s all ease-out;
  border-radius: 8px;
  position: relative;
  background-repeat: no-repeat;
  
  &:hover{
    color:$bg;
    background-color: $color;
    border-color: $bg;
    background-repeat: no-repeat;
    }
    
}


@mixin mainButton ($color, $bg, $font-size, $height, $width) {
  height:$height;
  width:$width;
  color:$color;
  font-size: $font-size;
  background-color: $bg;
  border:1px solid $color;
  text-align: center;
  line-height: $height;
  cursor: pointer;
  transition: .3s all ease-out;
  border-radius: 8px;
  position: relative;
  background-repeat: no-repeat;
  
  &:hover{
    color:$bg;
    background-color: $color;
    background-repeat: no-repeat;
    }
    
}

@mixin shadow($blur,$color){
  box-shadow: 0 0 $blur -5px $color;
}

@mixin headingOne($color:#064149){
  color: $color;
  font-family: 'DM Sans', sans-serif;
  margin:0 10px;
  font-size: 60px;
  line-height: 67px;
  font-weight: 700;
}

@mixin headingTwo($color:#064149){
  color: $color;
  font-family: 'DM Sans', sans-serif;
  margin:0 10px;
  font-size: 52px;
  font-weight: 700;
}

@mixin headingThree($color:#064149){
  color: $color;
  font-family: 'DM Sans', sans-serif;
  margin:0 10px;
  font-size: 44px;
  font-weight: 700;
}

@mixin headingFour($color:#064149){
  color: $color;
  font-family: 'DM Sans', sans-serif;
  margin:0 10px;
  font-size: 32px;
  font-weight: 700;
}

@mixin subheading($color:#064149){
  color: $color;
  font-family: 'DM Sans', sans-serif;
  margin:0 10px;
  font-size: 30px;
  font-weight: 300;
}

