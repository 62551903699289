@import '../../Styles/shared.scss';

.container{
  width: 100vw;
  box-sizing:border-box;
  padding:30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  position: relative;
  background-color: $faith-cream;
  box-sizing:border-box;
  padding:100px 0 0 0;

  section{
    width: 100%;
    min-height: 500px;
    padding: 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &.blueSection{
      background-color: $faith-blue;

      &.noPadd{
        padding: 0 !important;
      }

      h1{
        color: #fff;
      }
    }
  }

  .inner{
    width:95vw;
    max-width: 1400px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    img.school{
      position: relative;
      left: -150px;
    }

    h4{
      color: $faith-pink;
      margin: 10px 0;
    }
    h3{
      color: #fff;
      font-size: 38px;
      margin: 10px 0;;
    }

    &.row{
      flex-direction: row;
    }

    p{
      color: #fff;
      max-width: 500px;
    }

    &.hero{
      @media (max-width:1050px){
        flex-direction: column-reverse;
        text-align: center;
        align-items: center;

        .text{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }

        img.school{
          left: initial;
          bottom: -40px;
        }
      }
    }

    &.cards{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 1150px;


      .card{
        margin-bottom: 20px;
        height: 270px;
        width: calc(33% - 10px);
        background-color: white;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        box-sizing:border-box;
        padding:20px 50px 40px 50px;

        .imgContainer{
          min-height:100px;
          width:120px;
          // border:1px solid cyan;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        img{
          width: 80px;
        }

        p{
          width: 100%;
          font-size: 17px;
          color: $faith-blue;
          line-height: 24px;
        }

        @media (max-width:1150px){
          width: calc(50% - 10px);;
        }

        @media (max-width:750px){
          width: calc(100% - 50px);
          margin: 10px auto;
        }
      }
    }

    &.learnMore{
      align-items: flex-start;
      background: url('../../Assets//illustrations/school_coloured2b.png') no-repeat right 10vw  bottom -100px ,$faith-cream;
      background-size: 420px;
      border-radius: 10px;
      padding: 70px 100px;
      box-sizing: border-box;
      margin: 30px 0;
      
      img{
        height: 40px;
      }

      h3{
        color: $faith-blue;
        max-width: 500px;
        margin: 30px 0;
      }

      .btn{
        padding: 10px 25px;
        border-radius: 50px;
        background-color: $faith-pink;
        color: #fff;
        cursor: pointer;
        transition: .3s all ease;

        &:hover{
          background-color: #fa6358;
        }
      }

      @media (max-width:1100px){
        padding-bottom: 200px;
      }

      @media (max-width:850px){
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 380px;
        background: url('../../Assets//illustrations/school_coloured2.png') no-repeat center bottom -100px ,$faith-cream;
      }
    }
  }

}