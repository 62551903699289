@import '../../../../../Styles/shared.scss';

.container{
  p.subhead{
    width: 80vw;
    max-width: 950px;
    margin: 50px auto;
  }

  .slideRadios{
    display: flex;
    justify-content: center;
    width: 95%;
    margin: 0 auto;
    flex-wrap: wrap;

    .radioDiv{
      height: 90px;
      width: 30%;
      max-width: 280px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 8px;
      margin: 0 10px 20px 10px;
      box-sizing:border-box;
      padding:0 20px;
      cursor: pointer;
      opacity: .6;
      border:1px solid transparent;
      transition: .3s border-color ease;
      -webkit-box-shadow: 0px 0px 0px 2px transparant; 
      box-shadow: 0px 0px 0px 2px transparant;
      opacity: 1;

      &.narrow{
        width: 18%;
        margin: 0 5px 20px 5px;
      }

      &:hover{
        border-color: $faith-blue;
      }

      &.selected{
        -webkit-box-shadow: 0px 0px 0px 2px #064149; 
        box-shadow: 0px 0px 0px 2px #064149;
        opacity: 1;
      }
      
      .text{
        display: flex;
        align-items: center;
        height: 50px;

        h6{
          margin:0;
          font-weight: 700;
          font-size: 20px;
          // white-space: nowrap;
        }

        img{
          height:35px;
          margin-right: 10px;
        }
      }

      .progressBarContainer{
        margin-top: 10px;
        height: 6px;
        width: 100%;
        border-radius: 10px;
        background-color: #e9e9e9;

        .progressBar{
          height: 100%;
          width: 0%;
          background-color: $faith-pink;
          border-radius: 10px;
          transition: .2s width ease;
        }
      }
    }

    @media (max-width:950px){
      flex-direction: column;
      align-items: center;

      .radioDiv{
        width: 60vw !important;
        max-width: 450px;
        margin-bottom: 20px;
      }
    }
  }

  .slides{
    width: 85vw;
    margin: 0 auto;
    max-width: 1000px;
    position: relative;
    height: 350px;
    // margin-bottom: 100px;
   
    &:after{
      content:"";
      height: 40px;
      width: 300px;
      position: absolute;
      bottom:-70px;
      left:10px;
    }

    // &.faithJournals{
    //   &:before{
    //     background: url('../../../../../Assets/logos/faith_logo_classes.svg') no-repeat left center/contain;
    //   }
    // }

    // &.faithCademy{
    //   &:before{
    //     background: url('../../../../../Assets/logos/faith_logo_courses.svg') no-repeat left center/contain;
    //   }
    // }

    .slideContainer{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 60px auto;
      opacity: 0;
      box-sizing:border-box;
      padding:0 0px;
      transition: .8s all ease-in;
      
      &.show{
        opacity: 1;
        transition: 1.3s all ease-in;
      }

      &.hide{
        opacity: 0;
        transition: 0s all ease;
      }

      @media (max-width:700px){
        flex-direction: column;

        .text{
          width: 80%;
          text-align: center;
        }
      }
    }

    .imgCont{
      width: 50%;
      max-width: 550px;
      img{
        width: 100%;
      }
    }

    .text{
      text-align: left;
      width: 50%;
      box-sizing:border-box;
      padding:0 7%;

      p{
        font-size: 16px;
      }

      h4{
        margin: 20px 0;
        font-size: 50px;
        line-height: 55px;
      }
    }
  }
}