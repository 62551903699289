@import '../../../Styles/shared.scss';

.footerContainer {
  display: flex;
  // align-items: center;
  flex-direction: column;
  margin: 0 auto;
  box-sizing:border-box;
  padding:50px 150px;
  background-color: $faith-blue;
  height: 350px;

  .logosContainer{
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $faith-pink;
    position: relative;

    .socials{
      display: flex;
      align-items: center;

      >a{
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border:1px solid $faith-pink;
        border-radius: 50%;
        margin-left: 10px;
        cursor: pointer;

        img{
          height: 22px;
          opacity: .7;
          transition: .2s all ease-out;
        }


        &:last-child{
          box-sizing:border-box;
          padding:0 0 3px 0;
        }

        &:hover{
          img{
            opacity: 1;
          }
        }

      }
    }
  }

  .links{
    display: flex;
    align-items: center;
    padding: 30px 0;

    a{
      font-weight: 300;
      font-size: 14px;
      margin-right: 15px;
      color: rgba(255, 255, 255, 0.707);
      
      &:hover{
        color: white ;
      }
    }
  }

  .smallprint{
    top: 60px;
    position: relative;
    display: flex;
    align-items: center;
  }
  p.copyright{
    // position: absolute;
    font-weight: 300;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.707);
    left: 150px;
    height: 35px;
    display: flex;
    align-items: center;

    &:last-child{
      margin-left: 15px;
    }

    .escribli{
      height: 30px !important;
      margin-left: 10px;
      position: relative;
      bottom: 3px;
      cursor: pointer;
    }
    
  }

  img.cross{
    position: absolute;
    right: 150px;
    bottom: 0px;
  }

  @media (max-width:850px){
    padding:50px 80px;
  }

  @media (max-width:700px){
    height: fit-content;
    padding:50px 40px;

    .logosContainer{
      height: 80px;
    }
    
    .socials{
      position: absolute;
      top: 95px;
      left: -10px;
    }
    
    img.cross{
      right: 20px;
    }

    p.copyright{
      left: 40px;
    }
    
    .links{
      margin-top: 60px;
      flex-direction: column;
      align-items: flex-start;

      a{
        margin-bottom: 30px;
      }
    }
  }
}
