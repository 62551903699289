@import '../../Styles/shared.scss';

.container{
  background-color: $faith-cream;
  max-width: initial !important;
  min-height: calc(100vh - 80px);
  width: 100vw;
  // min-width: 1100px;
  box-sizing:border-box;
  padding: 100px 30px 30px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  &.firstStage{
    background:url('../../Assets/photos/kids_journals.png')no-repeat center bottom -170px;
    min-height: 880px;
  }

  h1{
    color: white;
    font-size: 40px;
    margin: 0 0 30px 0;
    font-weight: 700;
  }

  h3{
    font-size: 25px;
    line-height: 35px;
  }
  p{
    font-size: 16px;
    font-weight: 300;
  }

}