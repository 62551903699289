@import "./../Auth.module.scss";

.mainFormWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // height: calc(100% - 200px);
  flex: 1 1;
  text-align: left;
  h2 {
    color: $main-black;
    margin-bottom: 0.75em;
    font-size: 36px;
  }
  .or {
    color: $main-grey;
    font-size: 16px;
    margin: 0px;
  }
  .subtitle {
    color: $grey-unselected;
    margin-top: -1em;
  }
  .googleBtn {
    border:1px solid transparent !important;
    outline: none;
    background-image: url("../../../Assets/Icons//google/btn_google_signin_dark_normal_web@2x.png");
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    margin: -1em 0 .5em 0;
    width: 190px;
    height: 45px;
    border-radius: 0;
    cursor: pointer;
    
    &:hover{
      background-image: url("../../../Assets/Icons//google/btn_google_signin_dark_pressed_web@2x.png");
      
    }
  }
  .separator {
    display: flex;
    color: $main-grey;
    align-items: center;
    max-width: 380px;
    p {
      font-weight: bold;
      margin: 0px 1em;
    }
    .line {
      border-bottom: 2px solid $main-grey;
      height: 1px;
      flex-grow: 2;
    }
  }
}

.greenBtn {
  @include btn(white, $main-green, 4em);
  margin: 0.5em 0 !important;
  padding: 0 !important;
  height: 40px;
  width: 110px;
  border-radius: 8px;
  white-space: nowrap;

  &.errors {
    animation: error 0.5s ease;

    @include btn(white, $main-pink, 4em);
    &:hover{
      .pulseBubble{
        background-color: $main-pink;
      }
    }
  }

  &:hover{
    .pulseBubble{
      background-color: $main-green;
    }
  }
}

.spinnerBox {
  // width: 100px;
  // height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.pulseContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pulseBubble {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
}

.pulseBubble:first-child {
  animation: pulse 0.4s ease 0s infinite alternate;
}
.pulseBubble:nth-child(2) {
  animation: pulse 0.4s ease 0.2s infinite alternate;
}
.pulseBubble:last-child {
  animation: pulse 0.4s ease 0.4s infinite alternate;
}

@media (max-width: 900px) {
  
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0.25;
    transform: scale(0.75);
  }
}
