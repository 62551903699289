@import "./../Auth.module.scss";

.setPassword {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  flex: 1 1;
  // margin-top: -200px;
  text-align: left;
  h2 {
    margin-bottom: 1em;
    font-size: 28px;
    line-height: 34px;
  }
  main{
    width: 380px;
  }
  .submit {
    @include btn(white, $main-green, 4em);
    padding: 0.5em 4em;
    margin: 0.25em;
  }
  figure{
    width: 140px;
    padding: 10px;
    box-shadow: 0px 0px 30px 20px rgba(0,0,0,0.1);
    border-radius: 20px;
    margin: 40px auto;
  }
}
