@import '../../Homepage.module.scss';

.communityContainer{
  position: relative;
  
  .inner{
    width: 95vw;
    position: relative;
  }




  .cycle{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding:50px 0;
    position: relative;
    z-index: 10;


    .getStartedBtn{
      font-family: 'DM Sans', sans-serif;
      font-size: 18px;
      font-weight: 500 !important;
      cursor: pointer;
      transition: .25s ease-out all;
      padding:10px 20px;
      color: white;
      background-color: $faith-pink;
      margin: 20px 0 0 0;
      border-radius: 50px;
      border:1px solid $faith-pink;
      position: absolute;
      left: 50%;
      top: calc(50% + 150px);
      transform: translate(-50%,-50%);

      &:hover{
        background-color: #E45044;
      }

      @media (max-width:700px){
        display: none;
      }
    
    }
    
    div.arrow{
      position: absolute;
      top:calc(50% + 10px);
      left: 50%;
      transform: translate(-50%, -50%);
      // object-fit: contain;
      background: url('../../../../Assets/illustrations/arrow_circular1.svg') no-repeat center;
      background-size: 80vw 700px;
      width: 100%;
      height: 100%;
      max-height: 700px;
    }

    .quadrant{
      height: 400px;
      width: calc(50% - 2px);
      display: flex;
      align-items: center;
      justify-content: center;

      .cycleItemBlock{
        max-height: 70px;
        height: fit-content;
        width: 270px;
        padding:15px;
        border-radius: 15px;
        background-color: white;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        transform-style: preserve-3d;
        transition: .5s all ease, 1s cubic-bezier(0.29, 1.26, 0.71, 1.08) transform !important;
        cursor: pointer;
        transform: scale(0);
        
        &.show{
          transform: scale(1);
        }

        .chevron{
          display: none;
        }

        .initial{
          height: 70px;
          width: 270px;
          display: flex;
          align-items: center;
          margin-bottom: 15px;

        }

        .textWrapper{
            max-width: 350px;
            width: fit-content;
            max-height: 0;
            height: fit-content;
            overflow: hidden;
            transition: .5s all ease !important;
            margin-bottom: 10px;

            
            p.text{
            height: fit-content;
            width: 350px;
            font-size: 14px;
            line-height: 28px;
          }
        }


        .dot{
          min-height: 65px;
          max-height: 65px;
          min-width: 65px;
          max-width: 65px;
          background-color: #BFEDE6;
          border-radius: 50%;
          margin-right: 5px;
          display: flex;
          align-items: center;
          justify-content: center;

          img{
            height: 40px;
          }
        }

        &.educators,
        &.parishes{
          .dot>img{
            position: relative;
            height: 45px;
            bottom: 4px;
          }
        }

        &.parishes{
          bottom: 100px;
          left: 100px;
        }

        &.families{
          left: 100px;
        }

        &.administrators{
          bottom: 100px;
          right: 75px;
          width: 300px;
        }

        &:after{
          content:"";
          position: absolute;
          top: 13px;
          left: -10px;
          height: 100%;
          width: 100%;
          background-color: rgba(128, 128, 128, 0.2);
          border-radius: 15px;
          transform: translateZ(-1px);
        }

        &:hover{
          width: 390px !important;
          max-height: 350px !important;

          .textWrapper{
            max-height: 350px;
            max-width: 440px;
          }

          // &.administrators{
          //   right: 70px;
          // }
        }
      }
    }
  }

  .content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing:border-box;
    padding:0 0 80px 0;
    text-align: center;
    pointer-events: none;
    z-index: 1;

    img{
      margin-bottom: 20px;
    }

    h3{
      width: 800px;
      line-height: 50px;
    }
  }
  @media (max-width:1050px){
    .arrow{
      background:initial !important;
      border: 8px solid #F7D5CD;
      border-radius: 50%;
      width: 80vw !important;
    }

    .content{
      h3{
        width: 550px !important;
      }
    }

    .cycle{
      padding-top: 0 !important;
    }

    .quadrant{
      height: 520px !important;
    }

    .cycleItemBlock{
      left: initial !important;
      right: initial !important;
      top: initial !important;
      bottom: initial !important;

      &.administrators,
      &.educators{
        bottom: 50px !important;
      }
    }
  }

  @media (max-width:860px){
    .inner{
      display: flex;
      flex-direction: column-reverse;

      .content{
        position: relative !important;
        box-sizing:border-box;
        padding:30px 0 40px 0;

        h3, p{
          width: 80vw !important;
        }

        p:first-of-type{
          border:1px solid purple;
          display: none;
        }
      }
      .cycle{
        .arrow{
          display: none;
        }

        .quadrant{
          width: 100%;
          height: fit-content !important;
          min-height: 120px !important;

          .cycleItemBlock{
            width: 300px;
          }

          .administrators,
          .educators{
            bottom: 0px !important;
          }
        }
      }
    }
  }

  @media (max-width:600px){
    .inner{
      .cycle{
        .getStartedBtn{
          position: relative !important;
          top: 80px;
          width: 200px;
          height: 50px;
          font-size: 22px;
          line-height: 50px;
          text-align: center;
        }
        .quadrant{
          .cycleItemBlock{
            width: 90vw;

            &:after{
              display: none;
            }
            .chevron{
              display: initial;
              width: 60px;
              height: 60px;
              position: absolute;
              right:20px;
              background: url('../../../../Assets/Icons/chevron_darkBlue.svg') no-repeat center;
              transition: .3s all linear;
              transform: rotate(0deg);

              &.open{
                transform: rotate(180deg);
              }
            }

            .initial{
              width: 85vw !important;
            }

            &:hover{
              width: 90vw !important;
              max-height: 70px !important;
            }

            &.open{
              margin: 5px auto;
              top: 0 !important;
              left: 0 !important;
              right: 0 !important;
              bottom: 0 !important;
              width: 90vw !important;
              max-height: 250px !important;

              .textWrapper{
                max-height: 350px;
                max-width: 440px;
              }
            }
          }
        }
      }
    }
  }

}