@import '../../Styles/shared.scss';


img.logo{
  height: 50px;
  // width: 200px;
  object-fit: contain;
  @media screen and (max-width: 768px) {
    width: 150px;
    height: 60px;
  }
}
.container{
  width: 100%;
  min-height: 500px;
  padding: 40px 0 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;

  h1{
    margin: 80px auto 40px;
    max-width: 70vw;
    line-height: 70px;
    text-align: center;
    font-size: 32px;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .cards{
    width: 90%;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    background: #fff;
    -webkit-box-shadow: 5px 5px 1px 2px rgba(0,0,0,0.1); 
    box-shadow: 5px 5px 1px 2px rgba(0,0,0,0.1);
    border-radius: 15px;
  }

  @media screen and (max-width: 768px) {

  }

}
