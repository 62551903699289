@import "../../../Styles/shared.scss";
@import '../../../Styles/sharedModal.scss';

.loginModal {
  background-color: white;
  border-radius: 15px;
  box-shadow: 10px 10px 25px rgba(3, 8, 53, 0.1);
  padding: 20px;
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  max-width: 450px;

  h4{
    margin: 0 0 20px;
    padding: 0;
    font-size: 1.5rem;
    text-shadow: 0px 3px #ddd;
  }

  .products {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 10px;

    .product {
      display: flex;
      flex-direction: column;
      // flex-basis: 50%;
      border-radius: 10px;
      padding: 20px;
      gap: 15px;
      text-align: left;
    }

    .courses {
      background-color: #064149;
      color: #fff;
    }

    .classrooms {
      border: 1px solid #ccc;
    }

    .productDescription {
      font-size: 0.9rem;
      font-weight: normal;
      line-height: 1.8;
      height: 10rem;
    }

    .logoLine {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 3px;
      font-size: 0.8rem;

      img {
        height: 2rem;
        object-fit: contain;
      }
    }

    .coursesTitle, .classroomsTitle, .coursesAgeGroup, .classroomsAgeGroup {
      padding: 5px;
      border-radius: 10px;
    }

    .coursesTitle {
      font-size: 0.8rem;
      background-color: #ffa826;
    }

    .classroomsTitle {
      font-size: 0.8rem;
      background-color: #bfede6;
    }

    .coursesAgeGroup, .classroomsAgeGroup {
      width: fit-content;
      font-size: 0.8rem;
    }

    .coursesAgeGroup {
      background-color: #fff;
      color: #064149;
    }

    .classroomsAgeGroup {
      background-color: #e9e9e9;
      color: #333;
    }

    .actionButtons {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0;
    }

    .actionButton {
      border-radius: 20px;
      border:2px solid transparent;

      &:hover {
        border-color: $faith-pink;
      }

      a {
        padding: 10px;
        display: block;
        text-align: center;
        white-space: nowrap;
      }

      p {
        font-size: 14px;
        font-weight: bold;
        margin: 0;
        padding: 0;
      }

      .imgWrapper {
        img{
          height: 50px;
        }
      }
    }

    .courses .actionButton {
      &:hover {
        border-color: #ffa826; // $faith-pink;
      }
      
      p {
        color: #fff;
      }
    }
  }

  .closingBtn {
    cursor: pointer;
    position: absolute;
    top: 5%;
    right: 5%;
  }
}

@media(max-width: 700px) {
  .loginModal {
    width: 100%;
    height: 100%;
    overflow: auto;

    .products {
      margin-top: 10px;
      flex-direction: column;

      .productDescription {
        height: fit-content;
      }
    }
  }
}
