@import './Animations.scss';
@import './Fonts.scss';
@import './Mixins.scss';
@import './Variables.scss';

body {
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $main-black;
  background-color: $faith-cream;
}

input {
  font-family: 'DM Sans' sans-serif;
}

input:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
}

h1{
  color: #064149;
  font-family: 'DM Sans', sans-serif;
  // margin:0 10px;
  font-size: 60px;
  line-height: 67px;
  font-weight: 700;
}

h2{
  color: #064149;
  font-family: 'DM Sans', sans-serif;
  // margin:0 10px;
  font-size: 52px;
  font-weight: 700;
}

h3{
  color: #064149;
  font-family: 'DM Sans', sans-serif;
  // margin:0 10px;
  font-size: 44px;
  line-height: 50px !important;
  font-weight: 700;
}

h4{
  color: #064149;
  font-family: 'DM Sans', sans-serif;
  // margin:0 10px;
  font-size: 32px;
  font-weight: 700;
}

h5{
  color: #064149;
  font-family: 'DM Sans', sans-serif;
  // margin:0 10px;
  font-size: 28px;
  font-weight: 700;
}

h6{
  color: #064149;
  font-family: 'DM Sans', sans-serif;
  // margin:0 10px;
  font-size: 30px;
  font-weight: 300;
}

p{
  color: #064149;
  font-family: 'DM Sans', sans-serif;
  // margin:0 10px;
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;

}

  .italicQuote{
    font-style: italic;
    width: 60vw;
    max-width: 680px;
    margin: 0 auto;
    
    span{
      font-size: 18px;
      font-style: initial;
    }

    @media (max-width:1150px){
      width: 85VW;
      max-width: none;
    }
  }

button:focus {
  outline: none;
}

.App {
  // height: 100vh;
  position: relative;
  overflow-x: hidden;
  overflow-Y: hidden;
}

//Helpers

.hidden

.textWhite {
  color: white !important
}

.textGray {
  color: $main-black;
}

.whiteBg {
  background-color: white;
}

.lightbluegreyBg {
  background-color: $main-lightbluegrey
}

.orangeBrightBg {
  background-color: $main-orange;
}

.blueBg {
  background-color: $main-blue;
}

.lightblueBg {
  background-color: $main-blue--light;
}

.sectionInner {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;

  &.twelve{
    max-width: 1200px;
  }


  .halfContainer{
    width: 49%;
    margin: 0 5px 0 0;
    box-sizing: border-box;
    padding: 50px 70px;
    text-align: left;


    &.centered{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.spreadVertical{
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }

  }

  @media (max-width:1150px){
    flex-direction: column;
    align-items: center;

    .halfContainer{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: initial;
      text-align: center;
    }
  }
}

div.pageWrapper{
  max-width: 100vw;
  flex-direction: column;
  position: relative;
  top: 60px;
  transition: .3s ease-in-out all;

  @media (max-width:900px){
    top: 40px;
  
  }

  &.isSticky{
    top: 5px;
  }
}


.bookPurchaseCardDetails{
  overflow: hidden;
  height: 115px;
  width: 100%;
  position: relative;
  margin-top: 20px !important;

  .StripeElement{
    padding: 12px;
    border:1px solid #cacaca;
    border-radius: 5px;
    margin:3px 0;
  }
}

.autocomplete{
  width: 55vw !important;
  max-width: 800px !important;
  margin: 0 auto !important;
  position: relative !important;
  
  :last-child{
    border-bottom: 1px solid #d4d4d4; 
  }
  
}
.___optionsDiv___{
  width: 90% ;
  margin: 0 auto;
  position: absolute;
  left:0%;

  *{
    border:1px solid transparent !important;
  }
  

}

  .StripeElement{
    padding: 12px;
    border:1px solid #cacaca !important;
    border-radius: 5px;
    margin:3px 0;
  }



  .modal {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(51, 51, 51, 0.6);
    z-index: 100;
  
    &.show {
        display: flex;
        justify-content: center;
        align-items: center;
    }
  
    .closingBtn {
        cursor: pointer;
        position: absolute;
        top: 5%;
        right: 5%;
        &.outer {
            top: -5px;
            right: -10px;
        }
    }
  
    .pricingModal {
        background-color: white;
        border-radius: 20px;
        max-width: 780px;
        margin: 1em;
        position: relative;
    }
  }

  .masonry-grid {
    display: -webkit-box; 
    display: -ms-flexbox; 
    display: flex;
    justify-content:center;
    // margin-left: -30px; 
    padding:0 0 0 0px;
    width: 100%;
  }
  .masonry-grid_column {
    padding-right: 8px; /* gutter size */
    background-clip: padding-box;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .masonry-grid_column > div {
    margin-bottom: 15px !important;
  }