.container{
  width:760px;
  height: 428px;
  background-color: transparent;
  background: url('../../../Assets/loaders/spinner-sml--fast.svg') no-repeat center;
  background-size: 65px;
  position: relative;

  .closingBtn img {
    cursor: pointer;
    position: absolute;
    top: -15px;
    right: -15px;
  }

  p{
    font-size: 15px;
    color:white;
    position: absolute;
    bottom: -45px;
    left: 48%;
    transition: translateX(-50%);
    cursor: pointer;
    font-weight: 500;
    font-family: 'Sofia pro';

  }
}