

.shake{
  -webkit-animation: shake .4s ease-in-out 1;
  -moz-animation: shake .4s ease-in-out 1;
  -ms-animation: shake .4s ease-in-out 1;
  -o-animation: shake .4s ease-in-out 1;
  animation: shake .4s ease-in-out 1;
}

.shakeSlow{
  -webkit-animation: shake .5s ease-in-out 1;
  -moz-animation: shake .5s ease-in-out 1;
  -ms-animation: shake .5s ease-in-out 1;
  -o-animation: shake .5s ease-in-out 1;
  animation: shake .5s ease-in-out 1;
}

.shakeSm{
  -webkit-animation: shakeSm .4s ease-in-out 1;
  -moz-animation: shakeSm .4s ease-in-out 1;
  -ms-animation: shakeSm .4s ease-in-out 1;
  -o-animation: shakeSm .4s ease-in-out 1;
  animation: shakeSm .4s ease-in-out 1;
}


@keyframes shake{
  0%{
    transform:translateX(0)
  }
  20%{
    transform:translateX(5px)
  }
  40%{
    transform:translateX(-5px)
  }
  60%{
    transform:translateX(5px)
  }
  80%{
    transform:translateX(-5px)
  }
  100%{
    transform:translateX(0)
  }
}


@keyframes shakeSm{
  0%{
    transform:translateX(0)
  }
  20%{
    transform:translateX(2px)
  }
  40%{
    transform:translateX(-2px)
  }
  60%{
    transform:translateX(2px)
  }
  80%{
    transform:translateX(-2px)
  }
  100%{
    transform:translateX(0)
  }
}

@keyframes floating { 
  0% { transform: translate(0,  0px) translateZ(-1px); } 
  25%  { transform: translate(-5px, 10px) translateZ(-1px); } 
  50%  { transform: translate(-10px, 0px) translateZ(-1px); } 
  75%  { transform: translate(-5px, -10px) translateZ(-1px); } 
  100%   { transform: translate(0, 0px) translateZ(-1px); }     
} 



@keyframes floatingSm { 
  0% { transform: translate(0,  0px) translateZ(-1px); } 
  25%  { transform: translate(-3.5px, 3.5px) translateZ(-1px); } 
  50%  { transform: translate(-7px, 0px) translateZ(-1px); } 
  75%  { transform: translate(-3.5px, -3.5px) translateZ(-1px); } 
  100%   { transform: translate(0, 0px) translateZ(-1px); }     
} 



@keyframes bounceSm { 
  0% { transform: translate(0,  0px) translateZ(-1px); } 
  25%  { transform: translate(-1px, 5px) translateZ(-1px); } 
  50%  { transform: translate(-2px, 0px) translateZ(-1px); } 
  75%  { transform: translate(-1px, -5px) translateZ(-1px); } 
  100%   { transform: translate(0, 0px) translateZ(-1px); }     
} 

.floatingBefore{
  &:before{
    -webkit-animation: floating 1.5s linear infinite;
    -moz-animation: floating 1.5s linear infinite;
    -ms-animation: floating 1.5s linear infinite;
    -o-animation: floating 1.5s linear infinite;
    animation: floating 3.5s linear infinite;
  }
}

.floatingOffsetBefore{
  &:before{
    animation-delay: .6s;
    -webkit-animation: floating 3s linear infinite;
    -moz-animation: floating 3s linear infinite;
    -ms-animation: floating 3s linear infinite;
    -o-animation: floating 3s linear infinite;
    animation: floating 3s linear infinite;
  }
}


.floatingOffsetAfter{
  &:after{
    animation-delay: .6s;
    -webkit-animation: floatingSm 2s linear infinite;
    -moz-animation: floatingSm 2s linear infinite;
    -ms-animation: floatingSm 2s linear infinite;
    -o-animation: floatingSm 2s linear infinite;
    animation: floatingSm 2s linear infinite;
  }
}
.floatingAfter{
  &:after{
    -webkit-animation: floatingSm 2s linear infinite;
    -moz-animation: floatingSm 2s linear infinite;
    -ms-animation: floatingSm 2s linear infinite;
    -o-animation: floatingSm 2s linear infinite;
    animation: floatingSm 2s linear infinite;
  }
}


.bounceSm{
  -webkit-animation: bounceSm 1.5s linear infinite;
  -moz-animation: bounceSm 1.5s linear infinite;
  -ms-animation: bounceSm 1.5s linear infinite;
  -o-animation: bounceSm 1.5s linear infinite;
  animation: bounceSm 1.5s linear infinite;
}

// .floatingSmOffset{
//   animation-delay: .6s;
//   -webkit-animation: floatingSm .7s linear infinite;
//   -moz-animation: floatingSm .7s linear infinite;
//   -ms-animation: floatingSm .7s linear infinite;
//   -o-animation: floatingSm .7s linear infinite;
//   animation: floatingSm .7s linear infinite;

// }


@keyframes pulse {
  0% {
    transform:scale(.9);
  }
  50% {
    transform:scale(1);
  }
  89% {
    box-shadow: 0 0 0 50px #ffae0017;
  }
  90% {
    box-shadow: 0 0 0 50px #ffae0000;
  }
  100% {
    transform:scale(.9);
  }
}

.pulse{
  -webkit-animation: pulse 1s linear infinite;
  -moz-animation: pulse 1s linear infinite;
  -ms-animation: pulse 1s linear infinite;
  -o-animation: pulse 1s linear infinite;
  animation: pulse 1s linear infinite;
}

