@import '../../../../Styles/shared.scss';

.featureBlock{
  height: 200px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
  border-radius: 10px;
  transition: .3s background-color ease, .4s cubic-bezier(0.29, 1.26, 0.71, 1.08) transform;
  flex-direction: column;
  margin:6px;
  box-sizing:border-box;
  padding:15px;
  transform: scale(0);
  
  &.show{
    transform: scale(1);
  }

  p{
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    color: white;
    height: 40px;
    display: flex;
    align-items: center;
    margin: 20px 0 0 0;
    // display: none;
    transition: .3s all ease;
    color: $faith-blue;
  }

  img{
    height: 85px;
    width: 85px;
    transition: .3s all ease;
    object-fit: contain;
    &.hoverIcon{
      display: none;
    }
  }

  &:hover{
    background-color: $faith-blue;

    p{
      display: flex;
      color: #fff;
    }

    img{
      &.featureIcon{
        display: none;
      }
      &.hoverIcon{
        display: initial;
      }
    }
  }
}