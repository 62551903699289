@import "./../Auth.module.scss";

.forgotPassword {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  text-align: left;
  .header {
    margin-bottom: 2em;
    h2 {
      font-size: 30px;
      line-height: 47px;
    }
    h3 {
      font-size: 18px;
      line-height: 24px;
    }
  }
  .forgotModal {
    position: fixed;
    display: grid;
    place-items: center;
    width: 100vw;
    height: 100vh;
    .modalContainer {
      width: 660px;
      height: 340px;
      box-shadow: -5px -5px 25px rgba(6, 95, 229, 0.2), 5px 5px 25px rgba(6, 95, 229, 0.2);
      border-radius: 20px;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 2;
      padding : 2em;
      box-sizing: border-box;


      figure {
        width: 60px;
      }

      a{
        @include btn(white, $main-blue--bright,2em)
      }
    }
    &.close {
      z-index: -1;
      // animation: goAway 0.5s ease;
      opacity: 0;
    }
  }
}

@keyframes goAway {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-50px);
    opacity: 0;
  }
}
