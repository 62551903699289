@import '../../Styles/shared.scss';

.container{
  width: 100vw;
  box-sizing:border-box;
  padding:30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  position: relative;
  background-color: $faith-cream;
  box-sizing:border-box;
  padding:100px 0 0 0 ;

  &:after{
    content: "";
    height: 200px;
    width: 340px;
    background: url('../../Assets/illustrations/team.svg') no-repeat center;
    background-size: contain;
    position: absolute;
    bottom: 50px;
    right: -20px;

    @media (max-width:600px){
      width: 230px;
    }
  }


  .inner{
    width:95vw;
    max-width: 1400px;
    // min-width: 900px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2.title{
      width: 100%;
      text-align: left;
      margin: 60px 0 50px 0;
      padding-left: 30px;

      @media (max-width:1100px){
        text-align: center;
        margin-top: 40px;
      }
    }

    .howItStarted{
      transform-style: preserve-3d;
      position: relative;
      width: 100%;
      // height: 700px;
      background-color: white;
      border-radius: 25px;
      margin-bottom: 50px;
      border: 3px solid $faith-blue;
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: center;
      box-sizing:border-box;
      padding:90px;

      .imgContainer{
        width: 550px;
        display: flex;
        align-items: center;
        justify-content: center;
        
      }

      .text{
        width: calc(100% - 600px);

        h3{
          margin:0;
        }

        h6{
          font-size: 19px;
          line-height: 30px;
          font-weight: 700;
          margin:30px 0 50px 0;
        }

        p.italic{
          margin-top: 20px;
          font-style: italic;
        }

        .interviewDiv{

          h6{
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 20px;
            span{
              color: $faith-pink;
            }
          }

          .videoDiv{
            width: 300px;
            height: 162px;
            background: url('../../Assets/photos/interview.png') no-repeat center/cover;
            border-radius: 15px;
            border: 8px solid $faith-blue;
            cursor: pointer;
            transition: .3s all ease;
            transform-style: preserve-3d;
            position: relative;
            // transform: scale(1);
            bottom: 0px;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .play{
              height:50px;
              width:50px;
              background: url('../../Assets/Icons/play.svg') no-repeat center;
              transition: .2s all ease-in;
              background-size: cover;
            }
            
            &:hover{
              .play{
                height:60px;
                width:60px;
              }
            }
          }
        }
      }

      &::before{
        content: "";
        height: 300px;
        width: 260px;
        background: url('../../Assets/illustrations/dove_blue.svg') no-repeat center;
        background-size: contain;
        position: absolute;
        top: -190px;
        right: 40px;
        transform: translateZ(-1px);

        @media (max-width:600px){
          top: -180px;
        }
      }

      &:after{
        content:"";
        position: absolute;
        top: 13px;
        left: -10px;
        height: 100%;
        width: 100%;
        background-color: $faith-blue;
        border-radius: 25px;
        transform: translateZ(-1px);
      }

      @media (max-width:1400px){
        .imgContainer{
          width: 40%;
        }
        .text{
          width: calc(60% - 50px);
        }
      }

      @media (max-width:1100px){
        flex-direction: column;
        >*{
          width: 100% !important;
          text-align: center;
        }

        .imgContainer{
          margin-bottom: 50px;
        }

        &::before{
          opacity: .2;
        }
      }
      
    }

    .meetTheTeam{
      width: 100%;
      max-width: 1250px;
      position: relative;
      z-index: 10;

      h3.teamTitle{
        width: 100%;
        box-sizing:border-box;
        padding-left: 35px;
        font-size: 52px;
      }

      .teamMembers{
        display: flex;
        width: 80%;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-top: 30px;

        .teamMemberBlock,
        .phantomMember{
          width: 270px;
          margin-bottom: 40px;
          box-sizing:border-box;
          padding: 0 5px;

          img{
            margin-bottom: 8px;
            width: 250px;
            height: 250px;
            object-fit: cover;
            border-radius: 25px;
          }

          p{
            font-size: 18px;
            font-weight: 700;

            &.title{
              font-weight: 300;
              font-size: 14px;
            }
          }
        }
      }
    }
  }


  // @media (max-width:px){
  
  // }


}