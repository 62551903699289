@import "../../Styles/shared.scss";

.container{
  max-width: 100vw;
  flex-direction: column;
  position: relative;

  &.noScroll{
    overflow: hidden;
  }

    
  .overlay{
    position: absolute;
    width: 100%;
    height: calc(100% + 80px);
    background-color: rgba(48, 48, 48, 0.631);
    top: -80px;
    z-index: 100;
    pointer-events: none;
  }

  .partners{
    height: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .inner{
      position: relative;
      top: -90px;

      h4{
        margin:0 0 5px 0;
        color: white;
        font-size: 25px;
        font-weight: 700;
        width: 100%;
        text-align: left;
        padding-left: 10px;
      }
      
      .logos{
        background-color: white;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding:25px 30px;
        position: absolute;
        bottom: -100px;
        transform-style: preserve-3d;

        &:after{
          content:"";
          position: absolute;
          top: 10px;
          right: -8px;
          height: 100%;
          width: 100%;
          background-color: rgba(128, 128, 128, 0.2);
          border-radius: 20px;
          transform: translateZ(-1px);
        }

        img{
          height: 90px;
          margin: 0 30px
        }
      }
    }

    @media (max-width:650px){
      height: 260px;
      .logos{
        flex-direction: column;
        padding:50px 40px !important;
        img:first-of-type{
          margin-bottom: 30px;
        }
      }
    }
  }


}


.sectionContainer{
  width: 100vw;
  box-sizing:border-box;
  padding:30px 0;
  display: flex;
  justify-content: center;
  text-align: left;
  position: relative;

  &.noPadd{
    padding: 0 !important;
    height: fit-content !important;
  }


  .inner{
    width:95vw;
    max-width: 1400px;
    min-width: 900px;
    position: relative;
  }

}