.collaboration{
    padding: 4rem 1rem;
    position: relative;

    a{
        padding: 1rem 2rem;
        border-radius: 3rem;
        text-transform: capitalize;
        cursor: pointer;
        transition: all 0.3s ease;
        color: #fff;
        &:hover{
            transform: scale(0.9);
            opacity: 0.8;
        }
    }

    h6{
        font-size: 1.35rem;
        line-height: 1.9rem;
    }
    
    .center{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        a{
            background: #FD7D73;
        }
    }
    .header{
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: center;
        h4{
            font-weight: normal;
            font-size: 1.5rem;
        }
        img{
            width: 350px;
            object-fit: contain;
        }
        .shakes{
            text-align: center;
            img{
                margin-top: -10px;
                width: 250px;
            }
        }
    }

    .body{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        max-width: 1200px;
        margin: 1rem auto;
        @media screen and (max-width: 800px) {
            flex-direction: column;
        }
        .image{
            position: relative;
            .rosa{
                position: absolute;
                top: 30px;
                left: -38px;
            }
        }

        .main{
            p{
                text-align: left;
                width: 80%;
            }
            ul{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                text-align: left;
                gap: 1rem;
                font-weight: normal;
                color: #074B60;
            }
            a{
                background: #1E9AAA;
                font-size: 14px;
            }
            .right{
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
            }
        
            @media screen and (max-width: 800px) {
                width: 100%;
                p{
                    width: 100%;
                 }
                 ul{
                    font-size: 0.9rem;
                 }
             }
        }
    }

    .indent{
        margin-left: 4.5rem;
        @media screen and (max-width: 800px) {
            margin-left: 2.5rem;
        }
    }

    .left{
        width: 100%;
        margin-left: 25%;
        @media screen and (max-width: 800px) {
            margin-left: 0;
        }
        
        
    }

    .list{
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
        gap: 1rem;
        .item{
            display: flex;
            gap: 2rem;
            align-items: center;
            p{
                font-size: 1rem;
            }
            img{
                // width: 50px;
                // height: 50px;
                object-fit: contain;
            }
        }
    }

    .flowers{
        width: 400px;
        height: auto;
        position: absolute;
        right: 1rem;
        bottom: 0;
        img{
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
         @media screen and (max-width: 800px) {
             width: 250px;
             bottom: -30px;
         }
    }
    @media screen and (max-width: 800px) {
        .header{
            flex-direction: column;
        }
    }
}