

$main-black:#333333;
$main-blue: #2b4e7b;
$main-blue--bright: #065fe5;
$main-blue--light: #2f7ca0;
$main-orange: #ffa826;
$main-orange--light: #ffc300;
$main-green: #97d33b;
$main-pink: #ee6283;
$main-purple: #8e2bf0;
$main-purple--dark: #4E406E;

$main-grey: #858585; 
$main-grey--bee:#4B4A4A;
$main-bluegrey: #f0f4f6;
$main-lightbluegrey:#f3f7fd;
$grey-unselected: #bfcaca;
$grey: #e1e5e7;

$main-brown: #8b5e1b;
$main-brown_dark: #442f0f;
$main-brown_light: #8D7250;
$main-yellow_light:#ffdaa3;
$main-yellow_lighter:#ffecd0;
$main-yellow_dark:#ffecd0;

$faith-cream: #FCF2EA;
$faith-cream_dark: #F5E3D5;
$faith-blue: #064149;
$faith-pink:#FD7D73;
$faith-pink_light:#F7D5CD;
$faith-neptune: #7EB7AE;
$faith-aquamarine: #BFEDE6;
