@import '../../../Styles/shared.scss';


.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0.2rem 4rem;
  position: fixed;
  z-index: 15;
  width: 100vw;
  height: 80px;
  top: 0;
  transition: .3s all ease-in-out;
  font-size: 16px;
  font-weight: 700;
  background-color: $faith-cream;


  &.sticky{
    position: fixed;
    height: 55px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 25px 5px rgba(170, 133, 76, 0.25); 
    box-shadow: 0px 0px 25px 5px rgba(170, 133, 76,0.25);
    // &:hover{
    //   top: 0px;
    //   background-color: $faith-cream;
    // }
  }

  .main,
  .right{
    display: flex;
    align-items: center;
  }

  .main{
    min-width: 420px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:55%;
    left: 50%;
    transform: translate(-50%, -50%);

  }
  a.navItem{
    color:$main-brown ;
    transition: .15s ease-in-out all;
    cursor: pointer;
    margin: 0 20px;
    padding: 3px 0;;
    color: $faith-blue;
    font-family: 'DM Sans', sans-serif;
    border-bottom: 2px solid transparent;
    font-weight: 500;
  
    @media (max-width:1100px){
    margin: 0 10px;
    }
    @media (max-width:960px){
    margin: 0 5px;
    }
  
    &:hover{
      border: none;
      border-bottom: 2px solid $faith-pink;
    }

    &.navActive{
      border: none;
      border-bottom: 2px solid $faith-pink;
    }
  }
  
}

img.logo{
  height: 35px;
}

  .signinBtn,
  .signupBtn{
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 500 !important;
    cursor: pointer;
    transition: .15s ease-in-out all;
    color: $faith-blue;
    margin: 0;
    padding:10px 20px;
    border-radius: 50px;

    &:hover{
      color: white;
      background-color: $faith-blue;
    }
  }

.signupBtn{
  border:1px solid $faith-blue;
}

.signinBtn{
  padding:2px 5px;
  border-radius: 0;
  margin-right: 10px;
  border-bottom: 2px solid transparent;

  &:hover{
    border-bottom: 2px solid $faith-pink;
    background-color: transparent;
    color: $faith-blue;
  }
}

.resNav{  
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0.6rem 1rem;
  position: fixed;
  z-index: 15;
  width: 100vw;
  height: 80px;
  margin-top: 10px;
  top: 0;
  transition: .2s all ease-in-out;
  font-size: 16px;
  font-weight: 700;
  background-color: $faith-cream ;
  position: fixed;
  top: -10px !important;

  .topRow{
    width: 100%;
    min-height: 60px;
    max-height: 60px;
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    position: relative;
    a{
      height: 25px;
      .resLogo{
        height: 25px;
        margin: 0;
      }
    }

    .button{
      height: 60px;
      width: 60px;
      background:url('../../../Assets/Icons/button_circle_menu.svg') no-repeat center;
      background-size: contain;
      position: relative;
      z-index: 300;
    }
  
    svg{
      height: 1.5em ;
      width: 1.5em ;
      cursor: pointer;
      color: $main-brown;
      justify-self: flex-end;
      position: absolute;
      right:20px;
      top:5px;
    }
  }
  .dropdown{
    width: 400px;
    height: 0px;
    opacity: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    position: absolute;
    right: 10px;
    top: 5px;
    border-radius: 25px;
    box-sizing:border-box;
    padding:30px 50px;
    transition: .3s all ease;
    pointer-events: none;
    z-index: 200;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(50, 50, 50, 0.1);
    -moz-box-shadow:    0px 0px 10px 5px rgba(50, 50, 50, 0.1);
    box-shadow:         0px 0px 10px 5px rgba(50, 50, 50, 0.1);

    a, .link{
      margin: 0 0 55px 0;
      color:$faith-blue;
      font-size: 25px;
    }

    img.logo{
      margin-bottom: 60px;
    }
    img.cross{
      height: 190px;
      position: absolute;
      bottom: 0;
      right: 30px;
      opacity: 0;
      // width: 300px;
      // border:1px solid chartreuse;
    }

    @media (max-width:600px){
      top: -15px;
      // border-top-left-radius: 0;
      // border-top-right-radius: 0;
      padding-top: 40px;
      right: 0;
      width: calc(100vw - 30px);
      width: 100vw;
      right: 0px;
    }
  }

  .buttons{
    display: flex;
    align-items: center;

    .signupBtn{
      @include solidButton($main-brown, $main-orange--light, 16px, 30px, 100px);
      // padding-bottom: 5px;

      p{
        font-size: 16px;
        margin: 0;
        font-weight: 700;
      }
      &:hover{
        background-color: white;
      }
    }
  }


  &.open{
    .button{
      height: 60px;
      width: 60px;
      background:url('../../../Assets/Icons/button_circle_x.svg') no-repeat center;
      background-size: contain;
    }
   .dropdown{
     opacity: 1;
     height: 650px;
     pointer-events: all;

     img.cross{
       opacity: 1;
       transition: .3s all ease;
     }
   } 
  }
}
