@import '../../../../Styles/shared.scss';

.container{
  position: relative;
  display: flex;
  justify-content: center;
  background-color: $faith-pink;
  width: 100%;
  // margin-top: 70px;

  &.fullWidth{
    width: 100%;
  }
  &.paddBottom{
    padding-bottom: 150px;
  }
    
  .inner{
    width: 95vw;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;


    h4{
      font-size: 40px;
      line-height: 50px;
      color: white;

      &.subTitle{
        margin:80px 0;
      }
    }

    .titleText{
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      margin: 10px 0 20px 30px;
      width: 100%;
      max-width: 1100px;

      *{
        color:white;
        text-align: left;
      }
      h4{
        // margin-right: 70px;
        text-align: left;
        font-size: 45px;
        width: 100%;
      }
      p{
        font-size: 13px;
        width: 300px;
        line-height: 22px;
      }

      @media (max-width:900px){
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h4{
          margin-bottom: 30px;
        }

        *{
          width: 100%;
          text-align: center;
          margin-right: 0 !important;
        }
      }
    }

    .featureBlocks{
      max-width: 1100px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

    }

    .carouselWrapper{
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;

      &.carouselOnly{
        flex-direction: column;
        align-items: center;
        margin-top: 50px;

        h3{
          color: white;
          margin:80px 0 
        }
      }


      .carouselInner{
        width: 70vw;
        max-width: 900px;
        margin:0 0 140px 0;
        position: relative;
        display: flex;
        justify-content: center;


        .arrows{
          width: calc(70vw + 150px);
          max-width: 1200px;
          height: 100px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;
          justify-content: space-between;

          .arrow{
            height: 80px;
            width: 80px;
            background:url('../../../../Assets/Icons/circle_arrow_white.svg') no-repeat center;
            cursor: pointer;
            
            &:hover{
              background:url('../../../../Assets/Icons/circle_arrow_blue.svg') no-repeat center;
            }

            &.left{
              transform: rotate(180deg);
            }

            &.inactive{
              opacity: .4;
              pointer-events: none;
            }
          }
        }

        .slidesContainer{
          overflow: hidden;
          width: calc(70vw - 40px);
          max-width: 860px;
        }
      }



      .slides{
        width: fit-content;
        display: flex;
        align-items: center;
        position: relative;
        transition: .65s all ease-out;
        right: 0;

        &.two{
          right: calc(100% + 15px);
        }
      }
    }

    .tesimonialBlock{
      box-sizing:border-box;
      padding:50px 140px;
      border-radius: 15px;
      background-color: white;
      width: calc(70vw - 60px);
      // min-width: calc(70vw - 40px);
      max-width: 850px;
      text-align: left;
      position: relative;
      transform-style: preserve-3d;
      margin: 0 25px 20px 0px;

      &:after{
        content:"";
        position: absolute;
        top: 13px;
        right: -10px;
        height: 100%;
        width: 100%;
        background-color: rgba(128, 128, 128, 0.2);
        border-radius: 15px;
        transform: translateZ(-1px);
      }

      h1{
        font-size: 110px !important;
        position: relative;
        top: 20px;
        margin: 20px 0;
      }

      h5{
        font-size: 18px;
        line-height: 28px;
        font-weight: 300;
        margin-bottom: 40px;
      }

      .teacher{
        display: flex;
        align-items: center;

        img{
          margin-right: 20px;
        }

        .text{
          p{
            margin:0;
            line-height: 25px;
          }
        }
      }

      @media (max-width:750px){
        padding:60px 50px;
      }
    }
  }


  @media (max-width:650px){
    .carouselWrapper{
      .carouselInner{
        width: 90vw !important; 

        .arrows{
          display: none !important;
        }
        
        .slidesContainer{
          width: calc(90vw - 20px) !important; 
          
          .tesimonialBlock{
            width: calc(90vw - 30px)!important; 
            
          }
        }
      }
    }
  }
}