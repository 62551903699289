


  .toggle-button {
    position: relative;
    width: 120px;
    height: 50px;
    background-color: #eaeaea;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    margin: 20px 0;

    .rolling-ball {
      position: absolute;
      top: 2px;
      left: 2px;
      width: 46px;
      height: 46px;
      background-color: #fff;
      border-radius: 50%;
      transition: transform 0.3s ease-in-out;
    }
  

    .label-left {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
        font-weight: bold;
        left: 10px;
        color: #fff;
      }
    
      .label-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
        font-weight: bold;
        right: 10px;
        color: #064149;
      }
  
    &.active {
      background-color: #064149;
  
      .rolling-ball {
        transform: translateX(70px);
      }
  
      .label {
        color: #fff;
      }
    }
  }
  