@import '../../Styles/shared.scss';

.container{
  width: 100vw;
  box-sizing:border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  position: relative;
  background-color: $faith-cream;
  box-sizing:border-box;
  padding:60px 0 0 0;
  overflow: hidden;

  // &.noPadd{
  //   padding: 0 !important;
  // }

  section{
    width: 100%;
    min-height: 500px;
    padding: 60px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &.blueSection{
      background-color: $faith-blue;
    }
  }


  .inner{
    width:95vw;
    max-width: 1400px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    img.churchImg{
      position: relative;
      left: -150px;
    }

    h4{
      font-size: 22px;
      color: $faith-pink;
      margin: 10px 0;
    }
    h3{
      color: $faith-blue;
      font-size: 38px;
      margin: 10px 0;;
    }

    &.row{
      flex-direction: row;
    }

    p{
      color: $faith-blue;
      max-width: 500px;
    }

    .cards{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      max-width: 1200px;


      .card{
        margin-bottom: 20px;
        height: 270px;
        width: calc(33% - 10px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-sizing:border-box;
        padding:20px 50px 40px 50px;

        img{
          width: 80px;
        }
        h5{
          color: white;
          font-size: 23px;
          width: 250px;
          text-align: center;
        }

        @media (max-width:1150px){
          width: calc(50% - 10px);;
        }

        @media (max-width:750px){
          width: calc(100% - 50px);
          margin: 10px auto;
        }
      }
    }

  }



  .hero{
    .row{
      @media (max-width:1050px){
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
        align-items: center;
  
        .text{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          p{
            max-width: 700px;
          }
        }
  
        img{
          left: initial;
          bottom: -40px;
        }
      }
    }
  }

  .learnMore{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-size: 420px;
    border-radius: 10px;
    padding: 40px 100px 180px 100px;
    box-sizing: border-box;
    background: $faith-cream;
    position: relative;
    overflow: hidden;
    transform-style: preserve-3d;

    &::before{
      content: "";
      height:400px;
      width:400px;
      background: url('../../Assets/illustrations/faded_kids.svg') no-repeat center;
      position: absolute;
      left: calc(50% - 300px);
      bottom: -100px;
      transform: translateZ(-1px);
    }

    &::after{
      content: "";
      height:400px;
      width:400px;
      background: url('../../Assets/illustrations/faded_adult.svg') no-repeat center;
      position: absolute;
      right: calc(50% - 600px);
      bottom: -100px;
      transform: translateZ(-1px);
    }

    
    h3{
      max-width: 600px;
      text-align: center;
      margin-bottom: 30px;
    }

    .tools{
      width: 100%;
      display: flex;
      justify-content: space-between;


      .toolCard{
        width: 50%;
        box-sizing:border-box;
        padding:0 80px;
        padding: 0 40px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        // @media (max-width:1250px){
        // }


        h6{
          font-size: 12px;
          font-weight: 700;
          text-transform: uppercase;
          color: $faith-blue;
          padding: 5px 10px;
          border-radius: 20px;
          width: fit-content;
          margin: 20px 0;;

          &.green{
            background-color: #BFEDE6;
          }

          &.orange{
            background-color: #FFA826;
          }
        }

        img{
          height: 55px;
          position: relative;
          // left: -25px;
        }

        p{
          font-weight: 300;
          font-size: 15px;
          line-height: 30px;
          max-width: 400px;
          flex-grow: 1;
          margin: 30px 0;
        }
      }
    }

    .learnMoreBtn{
      padding: 10px 25px;
      border-radius: 50px;
      background-color: $faith-pink;
      color: #fff;
      cursor: pointer;
      transition: .3s all ease;
      width: fit-content;
      position: relative;
      z-index: 10;

      &:hover{
        background-color: #fa6358;
      }
    }



    @media (max-width:1180px){
      padding-bottom: 250px !important;
    }



    @media (max-width:950px){
      padding-bottom: 70px !important;
      position: relative;


        &::before{
          left: calc(50% - 400px);
          bottom: initial;
          top: 400px;
          height:250px;
          width:250px;
          background-size: contain;
        }

        &::after{
          bottom: initial;
          height:250px;
          width:250px;
          right: calc(50% - 350px);
          bottom: -70px;
          background-size: contain;
        }

      .tools{
        flex-direction: column;
        justify-content: center;
  
        .toolCard{
          width: 100%;
          align-items: center;
          margin:40px auto;
  
          *{
            text-align: center;
          }
        }
      }
    }
  }

}