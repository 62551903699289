@import '../../../../Styles/shared.scss';


  .stepOneContainer{
    
    h3{
      font-size: 28px;
      color: #333333;
      margin:5px 0;
    }
  
    p{
      font-size: 15px;
      color:#333333;
      margin:3px 0;
      // border:1px solid chartreuse;

      &.subText{
        text-align: left;
        // position: absolute;
        // bottom:8px;
    
        @media (max-width:1200px){
          font-size: 14px;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 90%;
          white-space: nowrap;
        }
    
        @media (max-width:1050px){
          font-size: 11px;
          max-width: 85%;
        }
      }

      &.openClassOrder{
        color: #077ca0;
      }
    }
    width:100%;
    box-sizing:border-box;
    box-sizing: border-box;
    padding:20px 0 0 0;

    .radios{
      width:100%;
      display:flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      height: 160px;

      label{
        width: calc(50% - 15px);
      }
      label>input{
        display:none
      }

      label>input+div.bookOption{
        text-align: left;
        box-sizing:border-box;
        padding:20px;
        height: 125px;
        border-radius: 10px;
        border:1px solid #cacaca;
        margin:2px;
        cursor: pointer;

        &.premium{
          // background:url('../../../../Assets/Bookstore/book-premium.svg') no-repeat right bottom;
          background-color: #f9f9f9;
          background-size: 300px;
          // background-position: right 0px;
        
        }

        &.standard{
          // background:url('../../../../Assets/Bookstore/book-standard.svg') no-repeat right 0px;
          background-color: #f9f9f9;
          background-size: 200px;
        }

        &.journal{  
          background: url('../../../../Assets/bookCovers/cover_journal.png') no-repeat 90% 10px;
          background-color: #f9f9f9;
          background-size: 120px
        }

        @media (max-width:1200px){
          h3{
            font-size: 20px;
          }
        }
      }
      label>input:checked+div.bookOption{
        border:3px solid $faith-neptune;
        margin:0;
        &.premium{
          background-position: right 1px;}
      }
      
      @media (max-width:1050px){
        flex-direction: column;
        height: fit-content;

        label{
          width: 100%;
        }
      }
    }

    p.subtext1{
      font-size: 12px;
      position: absolute;
      bottom:0;
      left: 0;

      @media (max-width:1050px){
        position: initial;
      }
    }
  }

  // .nextBtn{
  //   @include solidButton(white, $main-blue--bright, 16px, 40px, 170px);
  //   position: absolute;
  //   bottom:30px;
  // }

  .nextBtn{
    @include solidButton(white, $faith-pink, 16px, 40px, 170px);
    margin-top:40px;
    overflow: visible;
    // position: absolute;
    // bottom:40px;

    .error{
      text-align: left;
      width: 250px;
      color:red;
      font-size: 15px;
      position: absolute;
      left:180px;
      top:0px;
    }
  }

  .copiesDiv{
    position: relative;
    text-align: left;
    height: fit-content;
    margin-top: 30px;
    margin-bottom: 60px;
    min-height: 100px;
    
    h3{
      font-size:18px;
    }

    input[type='number']{
      width: 45px;
      height: 28px;
      border-radius: 3px;
      border:1px solid #cacaca;
      box-sizing: border-box;
      padding-left: 5px;

      &::-webkit-inner-spin-button{
        opacity: 1;
        height: 20px;
        cursor: pointer;
      }
    }
  }
  