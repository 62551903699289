@import '../../../../Styles/shared.scss';
@import '../../Homepage.module.scss';

.container{
  height: 800px;
  width: 100vw;
  position: relative;
  margin-top: 5rem;
  // z-index: 50;

  &.noPadd{
    .pinkBox{
      border-radius: 0 !important;
    }
  }

  .pinkBox{
    height: 650px;
    width: 100%;
    border-radius: 20px;
    background-color: $faith-pink;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &.staticBox{
      height: 700px;
    }

    &.overflow{
      overflow-y: visible !important;
    }
    

    .inner{
      height: 650px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      overflow: visible;

      &.static{
        position: relative;
      }

      .topBar{
        position: relative;
        width: 100%;
        margin-top: 30px;
        display: flex;
        align-items: center;

        .arrows{
          display: flex;
          align-items: center;
              
  
          .arrow{
            height: 65px;
            width: 65px;
            background:url('../../../../Assets/Icons/circle_arrow_white.svg') no-repeat center;
            background-size: contain;
            cursor: pointer;
            
            &:hover{
              background:url('../../../../Assets/Icons/circle_arrow_blue.svg') no-repeat center;
              background-size: contain;
            }

            &.left{
              transform: rotate(180deg);
              margin-right: 10px;
            }

            &.inactive{
              opacity: .4;
              pointer-events: none;
            }
          }
        }

        h3{
          color: white;
          font-size: 34px;
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          white-space: nowrap;
          margin:0
        }
      }
      .main{
        width: 100%;
        flex-grow: 2;
        // border:1px solid purple;
        position: relative;

        .imgSwitch{
          height: 550px;
          height: 550px;
          width: 65%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          &.staticSlide{
            height: 400px;
            margin-top: 20px;
          }

          img.slideImg{
            filter: blur(50px);
            
            &.focus{
              transition: .4s filter ease-in-out;
              filter: blur(0);
            }
            &.paddRight{
              padding-right: 100px;
            }
            &.sheets{
              position: absolute;
              bottom: -250px;
              left: -100px;
              &.topAlign{
                bottom: -50px;
              }
            }
          }
        }

        
        .staticSheets{
          bottom: -350px;
          width: 850px;
          left: initial;
          right: 130px;
          position: absolute;
          opacity: 0;

          &.show{
            opacity: 1;
            bottom: -200px;
            transition: .45s all ease-in-out;
          }
        }

        .testimonialText{
          margin-top: 30px;
          height:250px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 550px;
          // border:1px solid chartreuse;
          position: absolute;
          top: 0;
          right: 30px;
          box-sizing:border-box;
          padding:0 0 0 60px;

          &.staticText{
            position: relative;
            height: fit-content;
            width: 700px;
            right: 0;
            margin: 0;
            margin-top: 60px;
          }

          p{
            color: white;
            margin: 0;
            font-size: 22px;
            height: 100px;
            // border:1px solid chartreuse;
          }

          .bottom{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            box-sizing:border-box;
            padding:0 20px 0 0;

            p{
              font-weight: 700;
              font-size: 20px;
              opacity: .6;
              margin-bottom: 10px;
              padding-right: 5px;
              height: fit-content;
            }

            .dashes{
              width: 270px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .dash{
                height: 15px;
                width: 80px;
                border-radius: 50px;
                background-color: white;
                opacity: .4;
                cursor: pointer;

                &.active{
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }



  img.photo{
    position: absolute;
    bottom: -100px;
    opacity: 0;
    left: 55%;
    transform: translateX(-50%);

    &.staticPhoto{
      bottom: initial;
      top: -200px;
      transform: translate(-50%, 0);
      left: initial;
      left: 70%;
      right: 100px;
      opacity: 0;
      transition: .45s all ease-in-out;

      &.show{
        opacity: 1;
        top: -70px; 


      }
    }

    &.two{
      left: 75%;
    }
    
    &.show{
      opacity: 1;
      bottom: -30px;
      transition: .3s bottom ease-in-out, .6s opacity ease-in-out;
    }
  }

  &.carousel{

    @media (max-width:1400px){
      height: 1000px;
      .pinkBox,.inner{
        height:900px !important;
      }

      .topBar{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        h3{
          left: initial !important;
          transform: none !important;
          position: relative !important;
          margin-bottom: 20px !important;
        }
      }

      .main{
        height: 100%;
        display: flex;
        align-items: center;
        // justify-content: center;
        flex-direction: column;

        .imgSwitch{
          height: 450px !important;
          img.slideImg{
            left:initial !important;
            padding-right: initial !important;
            &.sheets{
              bottom: -250px !important;
              transform: scale(.9);
              &.topAlign{
                bottom: -60px !important;
              }
            }
          }
        }
      }

      .testimonialText{
        position: relative !important;
        left: initial;
      }

      img.photo{
        transform: scale(.7);

        &.two{
          display: none;
        }
      }
    
    }

  }

  &.staticContainer{

    @media (max-width:1550px){
      img.staticPhoto{
        left: 75% ;
      }
    }

    @media (max-width:1350px){
      img.staticPhoto{
        left: 78% ;
      }
    }
  
    @media (max-width:1200px){
      .staticText{
        width: 600px !important;
      }
    }

    @media (max-width:1150px){

      .staticBox{
        height: 800px;
      }

      .main{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column !important;        
      }

      .staticSheets{
        bottom: -220px !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
        width: 750px !important;
      }

      .slideImg{
        padding: 0 !important;
        width: 600px !important;
        box-sizing:border-box;
        padding:0 0 100px 0;
      }
      .staticPhoto{
        top: initial !important;
        width: 350px !important;
        bottom: -100px !important;
        right:00px !important;
        left: initial !important;
      }

      height: 800px;
      margin-bottom: 100px;
    }
  }


  @media (max-width:800px){
    display: none !important;
  }

}