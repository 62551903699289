@import '../../../../Styles/shared.scss';

.container{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;;

  .inner{
    width:95vw;
    max-width: 1200px;
    min-height:600px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing:border-box;
    padding:60px 40px;

    .tabRadios{
      width: 700px;
      display: flex;
      justify-content: center;

      .radio{
        height:90px;
        width:300px;
        border:1px solid #d2d2d2;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing:border-box;
        padding:0 20px;
        margin: 0 5px;
        text-align: left;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: .4s all ease;

        &:hover{
          border-color: $faith-blue;
        }

        &::before{
          content: "";
          // transform: rotate(-10deg);
          // position: absolute;
          // bottom: -20px;
          // right: 20px;
          // background: url('../../../../Assets/illustrations/school.svg') no-repeat center/contain;
          width: 260px;
          height: 100%;
          background: url('../../../../Assets/logos/faith_logo_classes.svg') no-repeat center/contain;
        }

        &.parish{
          &::before{
            // bottom: -30px;
            // right: 10px;
            // background: url('../../../../Assets/illustrations/church_coloured.png') no-repeat center/contain;
            width: 240px;
            height: 100%;
            background: url('../../../../Assets/logos/faith_logo_courses.svg') no-repeat center/contain;
          }
        }

        &.selected{
          border-color: $faith-blue;
          -webkit-box-shadow: 0px 0px 0px 1px #064149; 
          box-shadow: 0px 0px 0px 1px #064149;
        }

        h5{
          font-size: 24px;
          margin: 0;
        }
        h6{
          font-size: 17px;
          margin: 0;
          font-weight: 700;
          color: #82A0A4;
        }
      }

      @media (max-width:700px){
        width: fit-content;
        flex-direction: column;
        margin: 0 auto;

        .radio{
          margin-bottom: 20px;
        }
      }
    }
  }
}