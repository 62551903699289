@import '../../../../Styles/shared.scss';
@import '../../Homepage.module.scss';

.howItWorksContainer{
  background-color: $faith-blue;
  height: 850px;

  .inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h1, h2, h3, h4, p{
    color: white;
  }



  h1.title{
    top: 30px;
    left: 3vw;
    position: relative;
    white-space: nowrap;
  }

  .views{
    width: 700px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: calc(50% - 300px);
    // left: 90px;

    img{
      height: 70px;
      opacity: 0;

      &.active{
        opacity: 1;
      }
    }
  }

  .infoDiv{
    height: 600px;
    width: 40%;
    box-sizing:border-box;
    padding:0 0 0 40px;

    h1{
      margin-bottom: 20px;
    }

    .text{

      p{
        width: calc(100% - 70px);;
        height: 130px;
      }
    }

    .radios{
      display: flex;
      align-items: center;
    }
  
    .radio{
      height:50px;
      width:50px;
      border:1px solid white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #F2BCA0;
      font-size: 25px;
      cursor: pointer;
      margin-right: 8px;
      opacity: .4;

      &.selected{
        opacity: 1;
        pointer-events: none;
      }
    }
  }



  h4{
    line-height: 37px;
    margin:30px 0 25px 0;
  }

  p{
    &.radioBody{
      font-size: 14px;
      margin-top: 15px;
    }
  }

  .slides{
    width: 65%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    h1.resTitle{
      display: none;
    }

    .dots{
      height: 10px;
      width: 350px;
      position: absolute;
      bottom: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      display: none;

      .dot{
        width:20px;
        height: 12px;
        background-color: white;
        border-radius: 5px;
        opacity: .5;
        transition: .2s all ease;
        margin:0 2px;
        cursor: pointer;

        &.active{
          width: 40px;
          opacity: 1;
        }
      }
    }

    .slide{
      max-height: 550px;
      width: auto;
      position: absolute;
      opacity: 0;
      left: calc(50% - 150px);;
      top: 50%;
      transform: translate(-50%, -50%);
   
      
      img{
        border-radius: 35px;
        overflow: hidden;
        max-height: 450px;
        min-height: 400px;
        height: 45vh;
      }
      
      &.selected{
        transition: 1s opacity ease-in, 1s ease-out left !important;
        opacity: 1;
        left: 50%;
      }

      &.two{
        &:after{
          content:"";
          position: absolute;
          top: -99px;
          left: 50px;
          height: 100px;
          width: 150px;
          background: url('../../../../Assets/homepage/santo_top.svg') no-repeat center;
          background-size: contain;
        }
      }

      &.four{
        &:after{
          content:"";
          transform: scaleX(-1);
          position: absolute;
          top: 180px;
          left: -81px;
          height: 100px;
          width: 90px;
          background: url('../../../../Assets/homepage/santo_side.svg') no-repeat center;
          background-size: contain;
        }
      }

      @media (max-width:1200px){
        &::after{
          background: none !important;
        }
      }
    }
  }


  .btns{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: fit-content;
    
    .onboardingBtn{
      position: relative;
      width: fit-content;
      // left: -15px;
      padding: 15px 30px;
      border-radius: 30px;
      border:1px solid white;
      color: white;
      display: flex;
      align-items: center;
      font-size: 18px;
      cursor: pointer;
      transition: .3s all ease;
  
      &:hover{
        background-color: rgba(225, 225, 225, 0.1);
      }
  
      .arrow{
        background: url('../../../../Assets/Icons/arrow_pink_white.svg') no-repeat center/contain;
        height: 25px;
        width: 25px;
        margin-left: 15px;
      }
    }

        
    .contactBtn{
      font-family: 'DM Sans', sans-serif;
      font-size: 18px;
      font-weight: 500 !important;
      cursor: pointer;
      transition: .25s ease-out all;
      padding:10px 20px;
      color: white;
      background-color: $faith-pink;
      margin: 0;
      border-radius: 50px;
      border:1px solid $faith-pink;
      position: relative;
      top: 30px;
      // left: -15px;
  
      &:hover{
        background-color: #E45044;
      }
    }
  }


  @media (max-width:1200px){
    height: fit-content !important;
    // padding-bottom: 100px;

    .dots{
      display: flex !important;
    }
    .inner{
      flex-direction: column-reverse !important;

      .infoDiv{
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        height: 450px;
        padding:0;

        .radios{
          margin-bottom: 15px;
        }

        h1{
          position: relative;
          top: -50px;

          &.mainTitle{
            display: none;
          }
        }

        p{
          width: 400px;
          height: 80px;
        }
      }

      .views{
        display: none !important;
      }

      .slides{
        width: 100%;
        height: 550px;
        display: flex;
        justify-content: center;

        .slide{
          top: calc(50% + 40px);;
        }

        h1.resTitle{
          display: initial;
          position: absolute;
          top: 0px;
        }

        img{
          min-height: initial;
          height: 300px;
          border-radius: 22px;
        }

        .arrows{
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100vw;
          box-sizing:border-box;
          padding:0 30px;

          img{
            height: 60px;

            &.leftArrow{
              transform: rotate(180deg);
            }

            &.inactive{
              opacity: .4;
              pointer-events: none;
            }
          }

          @media (max-width:600px){
            padding:0 8px;
          }
        }

        .radiosInner{
          flex-direction: row;
          top: 0 !important;


          &.one{
            left: 150vw;
          }
          
          &.two{
            left: 50vw;
          }

          &.three{
            left: -50vw;
          }

          &.four{
            left: -150vw;
          }

          .radio{
            width: 70vw;
            margin: 0 15vw;
          }
        }
      }

      // .radios{
      //   height: 360px;
      // }
    }

    .onboardingBtn{
      position: relative;
      left: initial;
      bottom: initial;
      margin: 40px 0 30px 0;
    }
  }

  @media (max-width:600px){

    h1.title{
      font-size: 50px;
    }
    
    .slides{
      .slide{
        img{
          max-height: initial !important;
          max-width: 90vw;
          height: auto;
        }
      }
      
    }

    // .radios{
    //   height: 420px !important;
    //   box-sizing:border-box;
    //   padding:0 0 100px 0;


    //   .radiosInner{
    //     &.one{
    //       left: 172vw !important;
    //     }
    //     &.two{
    //       left: 58vw !important;
    //     }
    //     &.three{
    //       left: -58vw !important;
    //     }
    //     &.four{
    //       left: -172vw !important;
    //     }

    //     .radio{
    //       width: 85vw !important;
    //     }
    //   }

    //   .arrows{
    //     top: calc(100% - 40px) !important;
    //     box-sizing:border-box;
    //     width: 90vw !important;
    //   }
    // }
  }
}





// .radios{
//   width: 380px;
//   height: calc(100% + 60px);
//   position: relative;
//   overflow: hidden;

//   .arrows{
//     display: none;
//   }

//   .radiosInner{
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     position: relative;
//     transition: .4s all ease-out;
    
//     .radio{
//       width: 350px;
//       min-height: 320px;
//       max-height: 320px;
//       margin-bottom: 50px;
//       border: 2px solid white;
//       border-radius: 15px;
//       transition: .3s all ease-out;
//       opacity: .5;
//       transform: scale(0.95);
//       cursor: pointer;
//       box-sizing:border-box;
//       padding:35px 20px 20px 20px;

//       &.selected{
//         transform: scale(1);
//         opacity: 1;
//       }

//       p{
//         width: 260px;
//         line-height: 23px;

//         @media (max-width:1200px){
//           width: auto;
//         }
//       }
//     }

//     &.one{
//       top: 200px;
//     }

//     &.two{
//       top: -160px;
//     }

//     &.three{
//       top: -520px;
//     }

//     &.four{
//       top: -900px;
//     }

//   }
// }