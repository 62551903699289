@import '../../../../Styles/shared.scss';

.container{
  position: relative;
  display: flex;
  justify-content: center;
  // padding-top: 100px;
    
  .inner{
    width: 95vw;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;


    h3.benefitsTitle{
      width: fit-content;
      background: url('../../../../Assets/homepage/santo_pluma.svg') no-repeat center left;
      background-size: contain;
      padding-left: 100px;
      margin-bottom: 70px;
      height: 100px;
      line-height: 100px !important;
      
      @media (max-width:1250px){
        padding-left: 0;
        padding-bottom: 130px;
        line-height: 60px !important;
        background: url('../../../../Assets/homepage/santo_pluma.svg') no-repeat center bottom;
        max-width: 75vw;
      }
      
      @media (max-width:750px){
        background: none;
        font-size: 37px;
        max-width: 85vw;
        height: fit-content !important;
        padding-bottom: 10px;
        margin-top: 100px;
      }
    }

    .cards{
      display: flex;
      justify-content: space-between;
      width: 1255px;
      margin-bottom: 200px;

      .card{
        height: fit-content;
        width: 340px;
        height: 380px;
        padding:25px 35px;
        border-radius: 15px;
        background-color: white;
        position: relative;
        transform-style: preserve-3d;
        text-align: left;
        // transform: scale(0);
        opacity: 0;
        transition: .7s cubic-bezier(0.6, 0.96, 0.84, 1.09);

        img{
          margin-top: 20px;
        }

        @media (max-width:700px){
          width: 75vw !important;
        }
        
        
        // &:after{
        //   content:"";
        //   position: absolute;
        //   top: 13px;
        //   left: -10px;
        //   height: 100%;
        //   width: 100%;
        //   background-color: rgb(255, 255, 255);
        //   border-radius: 15px;
        //   transform: translateZ(-1px);
        //   opacity: 0;
        //   transition: .7s cubic-bezier(0.6, 0.96, 0.84, 1.09);
        // }
        
        &.show{
          // transform: scale(1);
          opacity: 1;
          
          // &::after{
          //   background-color: rgba(128, 128, 128, 0.2);
          //   opacity: 1;
          // }
        }

        h4.cardTitle{
          margin:30px 0;
        }

      }

      @media (max-width:1300px){
        flex-direction: column;
        width: 100VW;
        align-items: center;

        .card{
          width: 500px;
          margin-bottom: 20px;
        }
      }
    }
    
    .contactBtn{
      font-family: 'DM Sans', sans-serif;
      font-size: 18px;
      font-weight: 500 !important;
      cursor: pointer;
      transition: .25s ease-out all;
      padding:10px 20px;
      position: absolute;
      bottom: 100px;
      color: white;
      background-color: $faith-pink;
      margin: 0;
      border-radius: 50px;
      border:1px solid $faith-pink;
      margin-right: 15px;
  
      &:hover{
        background-color: #E45044;
      }
    }
  }

}