@import '../../../../Styles/shared.scss';

.container{

  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 1500px;
  box-sizing:border-box;
  padding:30px 150px;
  // border:3px solid red;

    .loadingOverlay{
      min-height: 100%;
      min-width: 100%;
      background-color: rgba(255, 255, 255, 0.9);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top:0;
      left: 0;
      z-index: 100;
      pointer-events: none;
      border-radius: 20px;

      img{
        height: 100px;
        width: 100px;
        object-fit: contain;
      }
      p{
        position: relative;
        bottom:10px;
        font-size: 17px;
        color:$faith-pink;
      }
  }

  .topBanner{
    border-radius: 15px;
    height: 40%;
    border:3px solid $faith-blue;
    background-color: white;
    box-sizing:border-box;
    box-sizing:border-box;
    padding:40px 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    transform-style: preserve-3d;

    .imgWrapper{
      height: 370px;
      width: 350px;
      position: absolute;
      right: 10px;
      bottom: 0px;
      overflow: hidden;

      img{
        height:100%;
        width:auto;
        position: relative;
        bottom: -60px;
        transform: rotate(-10deg);
      }

      @media (max-width:1200px){
        height: 300px;
        right: -50px !important;
      }

      @media (max-width:1150px){
        height: 250px;
      }

      @media (max-width:1000px){
        display: none;
      }
      
    }


    &:after{
      content:"";
      position: absolute;
      top: 13px;
      left: -10px;
      height: 100%;
      width: 100%;
      background-color: $faith-blue;
      border-radius: 25px;
      transform: translateZ(-1px);
    }

    .content{
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      position: relative;
      z-index: 10;

      h1, p{
        color: $faith-blue;
        text-align: left;
        margin:5px 0;
      }

      // h1{
      //   line-height: 30px;
      // }

      p{
        font-size: 14px;
        line-height: 21px;
        max-width: 470px;
      }

      .input{
        // display: flex;
        // align-items: center;
        // height: 40px;
        position: relative;
        margin-top: 30px;

        input[type='text']{
          height: 40px;
          width: 400px;
          border-radius: 5px;
          background-color: white;
          border:1px solid #cacaca;
          color:#333333;
          box-sizing:border-box;
          padding:0 10px;
          font-size: 16px;
          margin-bottom: 30px

        }

        .purchaseBtn{
          width: 150px;
          text-align: center;
          height: 45px;
          line-height: 45px;
          background-color: $faith-pink;
          border-radius: 40px;
          cursor: pointer;
          transition: .3s all ease;
          color: white;
          font-size: 15px;
          &:hover{
            background-color: #E45044;
          }

        }

        .error{
          color:#d00000;
          position: absolute;
          top:45px;
          left:2px;
          font-size: 13px;
        }
      }

    }

    .books{
      // position: absolute;
      // right: -20px;
      bottom: 0;
      overflow: hidden;
      width: 400px;
      height: 200px;
      // border:1px solid chartreuse;
      z-index: 1;

      .bookCover{
        width: 160px;
        position: relative;
        bottom: -50px;
        transform: rotate(-15deg);

        &:last-of-type{
          left: -30px;
        }
      }

      @media (max-width:1450px){
        right: -70px;

        .bookCover{
          width:130px
        }
      }
    }

    @media (max-width:1200px){
        
      h1{
        font-size: 30px;
      }

      .content{
        width: 100%;

        .input{
          input{
            width:100%;
          }
        }
      }

      .books{
        display: none;
      }
    }

  }



  .bottom{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: calc(60% - 40px);
    
    .bubble{
      width: calc(50% - 20px);
      border-radius: 15px;
      height: 310px;
      text-align:left;
      box-sizing:border-box;
      padding:30px 40px;
      
      cursor: pointer;
      transition: all .1s ease-in;
      position: relative;
      bottom:0;
  
      &:hover{
        bottom: 3px;
      }

      p{
        font-size:16px;
        width: 80%;
      }


      *{
        color:white
      }

      &.dark{
        // background:url('../../../../Assets/Bookstore/store-main--2.png') no-repeat right bottom;
        background-color: blue;
        // background-size: 90%;
      }
      &.light{
        // background:url('../../../../Assets/Bookstore/store-main--3.png') no-repeat center bottom;
        background-color: lightskyblue;
        background-size: 90%;
        
    
      }
    }
  }
  

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #cacaca;
      font-size: 14px;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: #cacaca;
      font-size: 14px;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: #cacaca;
      font-size: 14px;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: #cacaca;
      font-size: 14px;
    }

}