@import './../../Auth.module.scss';

.studentBoomerCard{
  cursor: pointer;
  position: fixed;
  background-color: $main-purple--dark;
  bottom: 0;
  right: 40%;
  border-radius: 10px 10px 0px 0px;
  width: 250px;
  text-align: left;
  padding: 0.75em 2em;
  box-sizing: border-box;
  transform: translateY(53px);
  transition: all 0.6s cubic-bezier(0.52, -0.56, 0.52, 1.27);

  img{
    position: absolute;
    top:-55px;
    right: 20px;
    height:70px;
    width:auto;

  }
  a{
    @include btn(white,$main-orange,1.5em);
    padding:0.35em 1.5em
  }
  p{
    color: white;
    font-weight: bold;
    margin: 0;
  }

  span{
    position: absolute;
    top: -52px;
    right: 20px;
  }
  &.open{
  transform: translateY(10px);
  }
}

@media(max-width: 900px){
  .studentBoomerCard{
    display: none;
  }
}