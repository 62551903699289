@import '../../../../Styles/shared.scss';

.heroContainer{
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 55px;
  padding-bottom: 50px;
  transition: .3s all ease-in-out;
  // margin-bottom: 150px;

  &.sticky{
    padding-top: 0;
  }

  img.illustration{
    position: absolute;
    width: 350px;
    top: 60%;
    transform: translateY(-50%);

    &.school{
      left: 78%;
      width: 330px;
      height: auto;
    }
    
    &.church{
      right: 78%;
      height: auto;
    }
  }

  .inner{
    max-width: 1400px;
    // height: 80vh;
    min-height: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing:border-box;
    padding:0;

    img{
      position: relative;
      top: 80px;
      width: 700px;
      height: auto;

      &.play{
        width: 65px;
        top: -70px;
        left: 100px;
        opacity: 0.8;
        transition: .5s all ease;
        cursor: pointer;
    
        &:hover{
          opacity: 1;
        }
      }
    }

    .inlineVideoPlayer {
      position: relative;
      top: -140px;
      right: -156px;
    }
    border-radius: 20px;

    h1{
      // @include headingOne();
      max-width: 950px;
      width: 80vw;
      font-size: 55px;
      margin-bottom: 10px
    }

    h6{
      // @include subheading();
      margin: 20px 0 40px 0;
      max-width: 750px;
      font-size: 33px;
      @media (max-width:1150px){
        width: 650px;
      
      }
    }

    .buttons{
      display: flex;
      align-items: center;

      .getStartedBtn,
      .contactBtn{
        font-family: 'DM Sans', sans-serif;
        font-size: 19px;
        font-weight: 500 !important;
        cursor: pointer;
        transition: .25s ease-out all;
        padding:10px 20px;

      }

      .getStartedBtn{
        color: white;
        background-color: $faith-pink;
        margin: 0;
        border-radius: 50px;
        border:1px solid $faith-pink;
        margin-right: 15px;
    
        &:hover{
          // color: $faith-pink;
          // border-color: $faith-pink;
          background-color: #E45044;
        }
      }

      .contactBtn{
        color: $faith-blue;
        padding-right: 25px;
        background: url('../../../../Assets/Icons/arrow_pink_blue.svg') center right no-repeat;
      }
    }
  }

    
  .partners{
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      bottom: 0px;
      z-index: 10;

      h4{
        margin: 20px 0;
      }

      .logos{
        border-radius: 20px;
        border: 5px solid #E9E6ED;
        background-color: white;
        display: flex;
        transform-style: preserve-3d;
        align-items: center;
        padding:25px 40px;

        img{
          height: 75px;
          margin: 0 30px;
          object-fit: contain !important;
          margin:20px;
          cursor: pointer;

          &:last-child{
            display: none;
          }
        }

        &:after{
          content:"";
          position: absolute;
          top: 15px;
          right: -18px;
          height: 100%;
          width: 100%;
          background-color: #e9e6edc4;
          border-radius: 20px;
          transform: translateZ(-1px);
        }
      }
    
      @media (max-width:1100px){
        // bottom: -300px;

        .logos{
          // flex-direction: column;
          img{
            // margin: 15px 0;
            height: 65px;
          }
        }
      }

      @media (max-width:900px){
        bottom: -120px;
      }

      @media (max-width:800px){
        // bottom: -500px;
        .logos{
          flex-direction: column;
        }
      }

      @media (max-width:700px){
        // bottom: -490px;
        // bottom: -590px;
      }
  }
}

@media (max-width:900px){

  .church{
    left: 80% !important;
  }

  .school{
    right: 90% !important;
  }
  .inner{
    min-height: 600px !important;
  }
}

@media (max-width:700px){
  .heroContainer{
    display: flex;
    flex-direction: column-reverse;
    height: fit-content;
    box-sizing:border-box;
    padding:50px 0 200px 0;
    overflow: visible;

    &.sticky{
      padding-top: 250px !important;
    }
  }
  .school{
    display: none;
  }

  .church{
    top: -80px !important;
    position: relative !important;
    // left: -13vw !important;
    left: 50% !important;
    transform: translateX(-54%) !important;
    width: 600px;;
  }

  .inner{
    h1{
      font-size: 45px;
      line-height: 55px;
    }
    h6{
      width: 350px !important;
      margin-top: 0 !important;
    }

    img {
      width: 80vw;
    }
  }
}

